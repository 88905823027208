 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="160px">
			<el-form-item class="el_form_item" :label="$dict('业务编号')">
				<el-input class="el_input" v-model="form.business_container_id" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" :label="$dict('booking')">
				<el-input class="el_input" v-model="form.booking_num" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" :label="$dict('箱型')">
				<el-select class="el_input" v-model="form.container_type_name" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option v-for="(item,index) in container_type_list" :key="index" :label="item.name" :value="item.name"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" :label="$dict('箱号')">
				<el-input class="el_input" v-model="form.container_number" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" :label="$dict('封号')">
				<el-input class="el_input" v-model="form.container_seal_number" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" :label="$dict('状态')">
				<el-select class="el_input" v-model="form.status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未提箱" value="1"></el-option>
					<el-option label="已预约提箱" value="2"></el-option>
					<el-option label="已到达提箱点" value="3"></el-option>
					<el-option label="提箱完成" value="4"></el-option>
					<el-option label="到达暂落点(提箱完成)" value="5"></el-option>
					<el-option label="装箱已预约" value="6"></el-option>
					<el-option label="已到达装货点" value="7"></el-option>
					<el-option label="装箱完成" value="8"></el-option>
					<el-option label="到达暂落点(装箱完成)" value="9"></el-option>
					<el-option label="进港已预约" value="10"></el-option>
					<el-option label="已到达码头" value="11"></el-option>
					<el-option label="进港完成" value="12"></el-option>
					<el-option label="到达暂落点(进港完成)" value="13"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" :label="$dict('货代')">
				<el-input class="el_input" v-model="form.forwarder_name" :disabled="true">
					<el-button slot="append" @click="forwarder_choose_open">选择</el-button>
				</el-input>
			</el-form-item>
			<el-form-item class="el_form_item" :label="$dict('堆场')">
				<el-input class="el_input" v-model="form.ctyard_name" :disabled="true">
					<el-button slot="append" @click="ctyard_choose_open">选择</el-button>
				</el-input>
			</el-form-item>
			<el-form-item class="el_form_item" :label="$dict('工厂')">
				<el-input class="el_input" v-model="form.factory_name" :disabled="true">
					<el-button slot="append" @click="factory_choose_open">选择</el-button>
				</el-input>
			</el-form-item>
			<el-form-item class="el_form_item" :label="$dict('港口')">
				<el-input class="el_input" v-model="form.port_name" :disabled="true">
					<el-button slot="append" @click="port_choose_open">选择</el-button>
				</el-input>
			</el-form-item>
			<el-form-item class="el_form_item" :label="$dict('备注')">
				<el-input class="el_input" v-model="form.mark" clearable></el-input>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">{{ $dict('查询') }}</el-button>
				<el-button type="success" @click="add_view_open()">{{ $dict('添加') }}</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column :label="$dict('添加/修改时间')" width="145">
					<template slot-scope="scope">
						<div>{{scope.row.create_time_text}}</div>
						<div>{{scope.row.update_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column :label="$dict('booking/箱型')">
					<template slot-scope="scope">
						<div>{{scope.row.booking_num}}</div>
						<div>{{scope.row.container_type_name}}</div>
					</template>
				</el-table-column>
				<el-table-column :label="$dict('货代')">
					<template slot-scope="scope">
						<div>{{scope.row.forwarder_info.name}}</div>
					</template>
				</el-table-column>
				<el-table-column :label="$dict('提箱堆场')">
					<template slot-scope="scope">
						<div>{{scope.row.ctyard_info.name}}</div>
						<div>{{scope.row.ctyard_info.addr}}</div>
					</template>
				</el-table-column>
				<el-table-column :label="$dict('工厂')">
					<template slot-scope="scope">
						<div>{{scope.row.factory_info.name}}</div>
						<div>{{scope.row.factory_info.addr}}</div>
					</template>
				</el-table-column>
				<el-table-column :label="$dict('码头')">
					<template slot-scope="scope">
						<div>{{scope.row.port_info.name}}</div>
						<div>{{scope.row.port_info.addr}}</div>
					</template>
				</el-table-column>
				<el-table-column :label="$dict('备注')" prop="mark" width="200"></el-table-column>
				<el-table-column :label="$dict('归属/状态')" width="160">
					<template slot-scope="scope">
						<div>{{ $dict('归属') }}:{{scope.row.belong_type_text}}</div>
						<div>{{ $dict('状态') }}:{{scope.row.status_text}}</div>
					</template>
				</el-table-column>

				<!-- 操作行 -->
				<el-table-column :label="$dict('操作')" width="85">
					<template slot-scope="scope">
						<div v-if="scope.row.status == 1">
							<el-button @click="ctyard_reserved_add_open(scope.row)" size="mini" type="text">{{ $dict('预约提箱') }}</el-button>
						</div>
						<div v-if="scope.row.belong_type == 3">
							<el-button @click="edit_view_open(scope.row)" size="mini" type="text">{{ $dict('修改') }}</el-button>
							<el-button v-if="scope.row.status == 1" @click="del(scope.row)" size="mini" type="text" style="color:red">{{ $dict('删除') }}</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change" 
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]" 
			:page-size="page.num" 
			:total="list.total*1" 
			layout="total, sizes,prev, pager, next, jumper" 
		></el-pagination>

		<!-- 添加弹出层 -->
		<add 
			:is_show="add_view.is_show" 
			:ctyard_list="ctyard_list" 
			@closed="add_view_close" 
		></add>

		<!-- 修改弹出层 -->
		<edit 
			:is_show="edit_view.is_show" 
			:data="edit_view.data" 
			:ctyard_list="ctyard_list" 
			@closed="edit_view_close" 
		></edit>

		<!-- 货代选择弹出层 -->
		<ctyardReservedAdd 
			:is_show="ctyard_reserved_add.is_show" 
			:booking_num="ctyard_reserved_add.booking_num" 
			@added="ctyard_reserved_added" 
		></ctyardReservedAdd>

		<!-- 货代选择弹出层 -->
		<forwarderChoose 
			:is_show="forwarder_choose.is_show" 
			@choosed="forwarder_choosed" 
		></forwarderChoose>

		<!-- 堆场选择弹出层 -->
		<ctyardChoose 
			:is_show="ctyard_choose.is_show" 
			@choosed="ctyard_choosed" 
		></ctyardChoose>

		<!-- 工厂选择弹出层 -->
		<factoryChoose 
			:is_show="factory_choose.is_show" 
			@choosed="factory_choosed" 
		></factoryChoose>

		<!-- 港口选择弹出层 -->
		<portChoose 
			:is_show="port_choose.is_show" 
			@choosed="port_choosed" 
		></portChoose>

	</div>
</template>

<script>
	import {mapState} from 'vuex'
	import add from './add.vue'
	import edit from './edit.vue'
	import ctyardReservedAdd from '../../ctyard/ctyard_reserved/add.vue'
	import forwarderChoose from '../../forwarder/forwarder_list/choose.vue'
	import ctyardChoose from '../../ctyard/ctyard_list/choose.vue'
	import factoryChoose from '../../factory/factory_list/choose.vue'
	import portChoose from '../../port/port_list/choose.vue'
	export default {
		components:{
			add,
			edit,
			ctyardReservedAdd,
			forwarderChoose,
			ctyardChoose,
			factoryChoose,
			portChoose,
		},
		data() {
			return {

				//基础数据
				container_type_list:[],

				//堆场列表
				ctyard_list:[],

				//搜索条件
				form: {
					business_container_id:'',
					booking_num:'',
					container_type_name:'',
					container_number:'',
					container_seal_number:'',
					forwarder_id:'',
					forwarder_name:'',
					ctyard_id:'',
					ctyard_name:'',
					factory_id:'',
					factory_name:'',
					port_id:'',
					port_name:'',
					mark:'',
					status:'',
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//添加弹窗
				add_view:{
					is_show:0,
				},

				//修改弹窗
				edit_view:{
					is_show:0,
					data:{},
				},

				//添加
				ctyard_reserved_add:{
					is_show:0,
					booking_num:'',
				},

				//货代选择
				forwarder_choose:{
					is_show:0,
				},

				//堆场选择
				ctyard_choose:{
					is_show:0,
				},

				//工厂选择
				factory_choose:{
					is_show:0,
				},

				//港口选择
				port_choose:{
					is_show:0,
				},
			}
		},
		computed:{
			...mapState(['basic_data'])
		},
		created() {
			
			//集装箱类型
			this.container_type_list=this.basic_data.container_type.arr;

			//读取页面数据
			this.page_ser()
		},
		methods: {
			
			//提箱预约
			ctyard_reserved_add_open(item){//打开
				this.ctyard_reserved_add.booking_num=item.booking_num
				this.ctyard_reserved_add.is_show++
			},
			ctyard_reserved_added(){//关闭
				this.ctyard_reserved_add.is_show=0
				this.$my.other.confirm({
					content:"预约成功,点击'确定'前往'提箱预约列表'",
					confirm:()=>{
						this.$router.push({
							path:'/pages/ctyard/ctyard_reserved_list',
							query:{
								booking_num:this.ctyard_reserved_add.booking_num
							}
						});
					}
				})
			},

			//货代选择
			forwarder_choose_open(){//打开
				this.forwarder_choose.is_show++
			},
			forwarder_choosed(obj){//关闭
				this.forwarder_choose.is_show=0
				this.form.forwarder_id=obj.forwarder_id
				this.form.forwarder_name=obj.name
			},

			//堆场选择
			ctyard_choose_open(){//打开
				this.ctyard_choose.is_show++
			},
			ctyard_choosed(obj){//关闭
				this.ctyard_choose.is_show=0
				this.form.ctyard_id=obj.ctyard_id
				this.form.ctyard_name=obj.name
			},

			//工厂选择
			factory_choose_open(){//打开
				this.factory_choose.is_show++
			},
			factory_choosed(obj){//关闭
				this.factory_choose.is_show=0
				this.form.factory_id=obj.factory_id
				this.form.factory_name=obj.name
			},

			//港口选择
			port_choose_open(){//打开
				this.port_choose.is_show++
			},
			port_choosed(obj){//关闭
				this.port_choose.is_show=0
				this.form.port_id=obj.port_id
				this.form.port_name=obj.name
			},

			//删除
			del(item){

				if(item.status != 1){
					this.$my.other.msg({
						type:'warning',
						str:"此项不能删除"
					});
					return;
				}

				//询问
				this.$my.other.confirm({
					content:"点击确定删除此项",
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'business',
								ctr:'business_container_del_by_truck_carrier',
								business_container_id:item.business_container_id,
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.page_ser()
							},
						});
					}
				});
			},

			//添加视图
			add_view_open(){//打开
				this.add_view.is_show++
			},
			add_view_close(){//关闭
				this.add_view.is_show=0
				this.page_ser()
			},

			//修改视图
			edit_view_open(item){//打开
				if(item.status != 1){
					this.$my.other.msg({
						type:'warning',
						str:"只有'未提箱'状态下可以修改"
					});
					return;
				}
				this.edit_view.data=item
				this.edit_view.is_show++
			},
			edit_view_close(){//关闭
				this.edit_view.is_show=0
				this.page_ser()
			},

			//搜索
			page_ser(){
				this.page.p=1
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){
				this.page.num=num
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				this.page.p=p
				this.get_page_data()
			},

			//读取用户组数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'business',
						ctr:'business_container_list_by_truck_carrier',
						is_get_forwarder_info:1,
						is_get_ctyard_info:1,
						is_get_factory_info:1,
						is_get_port_info:1,
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//取出各种info
							item.forwarder_info=item.forwarder_info[0]?item.forwarder_info[0]:{};
							item.ctyard_info=item.ctyard_info[0]?item.ctyard_info[0]:{};
							item.factory_info=item.factory_info[0]?item.factory_info[0]:{};
							item.port_info=item.port_info[0]?item.port_info[0]:{};

							//创建时间
							item.create_time_text=this.$my.other.totime(item.create_time);

							//上次修改时间
							item.update_time_text=this.$my.other.totime(item.update_time);

							//状态
							switch(item.status){
								case '1':item.status_text="未提箱";break;
								case '2':item.status_text="已预约提箱";break;
								case '3':item.status_text="已到达提箱点";break;
								case '4':item.status_text="提箱完成";break;
								case '5':item.status_text="到达暂落点(提箱完成)";break;
								case '6':item.status_text="装箱已预约";break;
								case '7':item.status_text="已到达装货点";break;
								case '8':item.status_text="装箱完成";break;
								case '9':item.status_text="到达暂落点(装箱完成)";break;
								case '10':item.status_text="进港已预约";break;
								case '11':item.status_text="已到达码头";break;
								case '12':item.status_text="进港完成";break;
								case '13':item.status_text="到达暂落点(进港完成)";break;
							}

							//业务归属(1:工厂,2:货代,3:车队,4:未指定)
							switch(item.belong_type){
								case '1':item.belong_type_text="工厂";break;
								case '2':item.belong_type_text="货代";break;
								case '3':item.belong_type_text="车队";break;
								case '4':item.belong_type_text="未指定";break;
							}
						}

						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		width: 90%;
		.children_list{
			margin-left: 20px;
		}
	}

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 190px);
	}
	.el_form_item{
		width: 25%;
	}
	.el_div{
		width: 80%;
		background-color: #F5F7FA;
		border-color: #E4E7ED;
		color: #C0C4CC;
		cursor: pointer;
		height: 32px;
    	line-height: 32px;
		border: 1px solid #DCDFE6;
		border-radius: 4px;
		padding: 0 15px;
	}
	.el_input{
		width:94%;
	}
	.btn_left{
		margin: 0;
	}
</style>