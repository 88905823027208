<template>
	<el-dialog :title="$dict('集装箱业务添加')" top="1vh" width="90%" :visible.sync="is_show_in_page" @closed="closed">

		<!-- 表单 -->
    	<el-form class="form" label-width="140px">

			<div class="big_tit" style="margin: 0">{{ $dict('booking/相关单位') }}</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="bookingNo" style="width:30%">
					<el-input class="el_inner_width" v-model="form.booking_num" :disabled="booking_num_has_ser" clearable>
						<el-button slot="append" @click="booking_num_ser">{{ $dict('查找') }}</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" :label="$dict('货代')" style="width:35%">
					<el-input class="el_inner_width" v-model="form.forwarder_name" :disabled="true">
						<el-button slot="append" @click="forwarder_choose_open">{{ $dict('选择') }}</el-button>
						<el-button slot="append" @click="forwarder_clear">{{ $dict('清空') }}</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" :label="$dict('港口')" style="width:35%">
					<el-input class="el_inner_width" v-model="form.port_name" :disabled="true">
						<el-button slot="append" @click="port_choose_open">{{ $dict('选择') }}</el-button>
						<el-button slot="append" @click="port_clear">{{ $dict('清空') }}</el-button>
					</el-input>
				</el-form-item>
      		</div>


      		<div class="big_tit">
				<div>{{ $dict('集装箱列表') }}</div>
				<el-button @click="business_container_detail_add" type="primary" size="mini" style="position: absolute; left: 120px; top: 8px">{{ $dict('增加一行') }}</el-button>
	      	</div>
      		<div class="tab_height">
        		<el-table :data="business_container_detail" :border="true" :stripe="true" size="small" height="100%">
					<el-table-column :label="$dict('箱型')" width="170">
						<template slot-scope="scope">
							<el-select v-model="scope.row.container_type_name" style="width:100%" :disabled="scope.row.status!=1" clearable>
								<el-option v-for="(item,index) of container_type_list" :label="item.name" :value="item.name" :key="index"></el-option>
							</el-select>
						</template>
					</el-table-column>
					<el-table-column :label="$dict('堆场(提箱点)')" width="400">
						<template slot-scope="scope">
							<el-input class="el_inner_width" v-model="scope.row.ctyard_name" :disabled="true">
								<el-button slot="append" @click="ctyard_choose_open(scope.row)">{{ $dict('选择') }}</el-button>
								<el-button slot="append" @click="ctyard_clear(scope.row)">{{ $dict('清空') }}</el-button>
							</el-input>
						</template>
					</el-table-column>
					<el-table-column :label="$dict('工厂(装箱点)')" width="400">
						<template slot-scope="scope">
							<el-input class="el_inner_width" v-model="scope.row.factory_name" :disabled="true">
								<el-button slot="append" @click="factory_choose_open(scope.row)">{{ $dict('选择') }}</el-button>
								<el-button slot="append" @click="factory_clear(scope.row)">{{ $dict('清空') }}</el-button>
							</el-input>
						</template>
					</el-table-column>
					<el-table-column :label="$dict('业务归属')" width="160">
						<template slot-scope="scope">
							<el-select class="el_inner_width" v-model="scope.row.belong_type" :disabled="scope.row.status!=1">
								<el-option label='工厂' value="1"></el-option>
								<el-option label='货代' value="2"></el-option>
								<el-option label='车队' value="3"></el-option>
								<el-option label='未指定' value="4"></el-option>
							</el-select>
						</template>
					</el-table-column>
					<el-table-column :label="$dict('备注')" >
						<template slot-scope="scope">
							<el-input v-model="scope.row.mark" clearable></el-input>
						</template>
					</el-table-column>

					<!-- 操作行 -->
					<el-table-column :label="$dict('操作')" width="85">
						<template slot-scope="scope">
							<el-button @click="business_container_detail_add(scope.$index, scope.row)" size="mini" type="text">{{ $dict('复制') }}</el-button>
							<el-button 
								v-if="scope.row.status == 1" 
								@click="business_container_detail_del(scope.$index)" 
								size="mini" type="text" style="color: red"
							>{{ $dict('删除') }}</el-button>
						</template>
					</el-table-column>
        		</el-table>
      		</div>
			<div style="text-align:center;margin-top:15px;">
				<el-button @click="sub" type="primary">{{ $dict('提交') }}</el-button>
			</div>
    	</el-form>
		
		<!-- 货代选择弹出层 -->
		<forwarderChoose 
			:is_show="forwarder_choose.is_show" 
			@choosed="forwarder_choosed" 
		></forwarderChoose>

		<!-- 堆场选择弹出层 -->
		<ctyardChoose 
			:is_show="ctyard_choose.is_show" 
			@choosed="ctyard_choosed" 
		></ctyardChoose>

		<!-- 工厂选择弹出层 -->
		<factoryChoose 
			:is_show="factory_choose.is_show" 
			@choosed="factory_choosed" 
		></factoryChoose>

		<!-- 港口选择弹出层 -->
		<portChoose 
			:is_show="port_choose.is_show" 
			@choosed="port_choosed" 
		></portChoose>

  	</el-dialog>
</template>
<script>
	import {mapState} from 'vuex'
	import forwarderChoose from '../../forwarder/forwarder_list/choose.vue'
	import ctyardChoose from '../../ctyard/ctyard_list/choose.vue'
	import factoryChoose from '../../factory/factory_list/choose.vue'
	import portChoose from '../../port/port_list/choose.vue'
	export default {
		components:{
			forwarderChoose,
			ctyardChoose,
			factoryChoose,
			portChoose,
		},
		props: {
			is_show: Number,
		},
		data() {
			return {

				//基础数据
				container_type_list:[],

				//是否显示
				is_show_in_page: false,

				//booking是否搜索过
				booking_num_has_ser:false,

				//表单
				form: {
					booking_num:"",
					forwarder_id:0,
					forwarder_name:'',
					port_id:0,
					port_name:'',
				},

				//货代选择
				forwarder_choose:{
					is_show:0,
				},

				//堆场选择
				ctyard_choose:{
					business_container_item:{},
					is_show:0,
				},

				//工厂选择
				factory_choose:{
					business_container_item:{},
					is_show:0,
				},

				//港口选择
				port_choose:{
					is_show:0,
				},
				
				//堆场可预约时段列表
				business_container_detail:[],
			};
		},
		watch: {
			is_show(new_data) {
				if (new_data) {

					//打开弹出层
					this.is_show_in_page = true;

					//初始化
					this.init();

				} else this.is_show_in_page = false;
			},
		},
		computed:{
			...mapState(['basic_data'])
		},
		created(){

			//集装箱类型
			this.container_type_list=this.basic_data.container_type.arr;
		},
 	 	methods: {

			//页面关闭
			closed(){
				this.$emit("closed");
			},

			//堆场可预约时段保存
			sub(){
				if(!this.booking_num_has_ser){
					this.$my.other.msg({
						type:'warning',
						str:"请先查找booking信息"
					});
					return;
				}
				if(!this.form.booking_num.trim()){
					this.$my.other.msg({
						type: "warning",
						str: `缺少booking编号`,
					});
					return;
				}
				//汇集需要修改的集装箱业务
				let business_container_edit=[];
				let business_container_add=[];
				for(let index in this.business_container_detail){

					//取出item
					index = parseInt(index);
					let item=this.business_container_detail[index];
					if(item.status!=1)continue;

					//重要数据校验
					if(!item.container_type_name.trim()){
						this.$my.other.msg({
							type: "warning",
							str: `第 ${index + 1} 条缺少箱型`,
						});
						return;
					}
					if(!this.$my.check.is_num(item.ctyard_id)){
						this.$my.other.msg({
							type: "warning",
							str: `第 ${index + 1} 条缺少堆场(提箱点)`,
						});
						return;
					}

					//业务归属
					if(item.belong_type==1){//工厂
						
						if(!item.factory_id){
							this.$my.other.msg({
								type:'warning',
								str:"此业务没有声明工厂信息,不能设定工厂作为业务归属"
							});
							return;
						}

					}else if(item.belong_type==2){//货代

						if(!this.form.forwarder_id){
							this.$my.other.msg({
								type:'warning',
								str:"此业务没有声明货代信息,不能设定货代作为业务归属"
							});
							return;
						}

					}

					//状态区分
					if(item.business_container_id){//有id,置入待修改数据
						business_container_edit.push({
							where:{
								business_container_id:item.business_container_id,
							},
							set:{
								belong_type:item.belong_type,
								container_type_name:item.container_type_name,
								booking_num:this.form.booking_num,
								forwarder_id:this.form.forwarder_id,
								ctyard_id:item.ctyard_id,
								factory_id:item.factory_id,
								port_id:this.form.port_id,
								mark:item.mark.trim(),
							}
						});
					}else{//没有id,置入待添加数据
						business_container_add.push({
							belong_type:item.belong_type,
							container_type_name:item.container_type_name,
							booking_num:this.form.booking_num,
							forwarder_id:this.form.forwarder_id,
							ctyard_id:item.ctyard_id,
							factory_id:item.factory_id,
							port_id:this.form.port_id,
							mark:item.mark.trim(),
						})
					}
				}

				//询问
				this.$my.other.confirm({
					content:"点击确定保存集装箱业务数据",
					confirm:()=>{

						//待修改数据
						if(business_container_edit.length > 0){

							this.$my.net.req({
								data:{
									mod:"business",
									ctr:"business_container_edit_by_truck_carrier",
									edit_list:business_container_edit,
								},
								callback:(data)=>{
									this.$my.other.msg({
										type:'success',
										str:"需修改部分保存成功"
									});
								},
							});
						}

						//待添加数据
						if(business_container_add.length > 0){

							this.$my.net.req({
								data:{
									mod:"business",
									ctr:"business_container_add_by_truck_carrier",
									add_list:business_container_add,
								},
								callback:(data)=>{
									this.$my.other.msg({
										type:'success',
										str:"需添加部分保存成功"
									});
								},
							});
						}
					}
				});
			},

			//booking信息查找
			booking_num_ser(){

				if(!this.form.booking_num.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"请输入booking"
					});
					return;
				}

				//调用接口
				this.$my.net.req({
					loading:true,
					data:{
						mod:"business",
						ctr:"business_container_list_by_truck_carrier",
						booking_num:this.form.booking_num.trim(),
						is_get_forwarder_info:1,
						is_get_ctyard_info:1,
						is_get_factory_info:1,
						is_get_port_info:1,
					},
					callback:(data)=>{
						
						this.booking_num_has_ser=true;
						if(data.list.length==0)return;

						//汇集集装箱列表
						let business_container_detail=[];
						for(let item of data.list){

							//取出各种info
							item.forwarder_info=item.forwarder_info[0]?item.forwarder_info[0]:{};
							item.ctyard_info=item.ctyard_info[0]?item.ctyard_info[0]:{};
							item.factory_info=item.factory_info[0]?item.factory_info[0]:{};
							item.port_info=item.port_info[0]?item.port_info[0]:{};

							business_container_detail.push({
								status:item.status,
								belong_type:item.belong_type,
								business_container_id:item.business_container_id,
								container_type_name: item.container_type_name,
								ctyard_id: item.ctyard_info.id,
								ctyard_name: item.ctyard_info.name,
								factory_id: item.factory_info.id,
								factory_name: item.factory_info.name,
								mark: item.mark,
							});
						}
						this.business_container_detail=business_container_detail;

						//货代和港口
						let business_container_item=data.list[0];
						this.form.forwarder_id=business_container_item.forwarder_info.id
						this.form.forwarder_name=business_container_item.forwarder_info.name
						this.form.port_id=business_container_item.port_info.id
						this.form.port_name=business_container_item.port_info.name
					},
				});
			},

			//货代选择
			forwarder_choose_open(){//打开
				if(!this.booking_num_has_ser){
					this.$my.other.msg({
						type:'warning',
						str:"请先查找booking信息"
					});
					return;
				}
				this.forwarder_choose.is_show++
			},
			forwarder_choosed(obj){//关闭
				this.forwarder_choose.is_show=0
				this.form.forwarder_id=obj.forwarder_id
				this.form.forwarder_name=obj.name
			},
			forwarder_clear(){//清空
				this.form.forwarder_id=0
				this.form.forwarder_name=''
			},

			//堆场选择
			ctyard_choose_open(business_container_item){//打开
				if(!this.booking_num_has_ser){
					this.$my.other.msg({
						type:'warning',
						str:"请先查找booking信息"
					});
					return;
				}
				if(business_container_item.status!=1)return;
				this.ctyard_choose.business_container_item=business_container_item;
				this.ctyard_choose.is_show++
			},
			ctyard_choosed(obj){//关闭
				this.ctyard_choose.is_show=0
				this.ctyard_choose.business_container_item.ctyard_id=obj.ctyard_id
				this.ctyard_choose.business_container_item.ctyard_name=obj.name
			},
			ctyard_clear(business_container_item){
				if(business_container_item.status!=1)return;
				business_container_item.ctyard_id=0
				business_container_item.ctyard_name=''
			},

			//工厂选择
			factory_choose_open(business_container_item){//打开
				if(!this.booking_num_has_ser){
					this.$my.other.msg({
						type:'warning',
						str:"请先查找booking信息"
					});
					return;
				}
				if(business_container_item.status!=1)return;
				this.factory_choose.business_container_item=business_container_item;
				this.factory_choose.is_show++
			},
			factory_choosed(obj){//关闭
				this.factory_choose.is_show=0
				this.factory_choose.business_container_item.factory_id=obj.factory_id
				this.factory_choose.business_container_item.factory_name=obj.name
			},
			factory_clear(business_container_item){
				if(business_container_item.status!=1)return;
				business_container_item.factory_id=0
				business_container_item.factory_name=''
			},

			//港口选择
			port_choose_open(){//打开
				if(!this.booking_num_has_ser){
					this.$my.other.msg({
						type:'warning',
						str:"请先查找booking信息"
					});
					return;
				}
				this.port_choose.is_show++
			},
			port_choosed(obj){//关闭
				this.port_choose.is_show=0
				this.form.port_id=obj.port_id
				this.form.port_name=obj.name
			},
			port_clear(){//清空
				this.form.port_id=0
				this.form.port_name=''
			},

			//堆场可预约时段添加/删除
			business_container_detail_add(index = -1, data) {
				if(!this.booking_num_has_ser){
					this.$my.other.msg({
						type:'warning',
						str:"请先查找booking信息"
					});
					return;
				}
				if (index == -1) {
					index = this.business_container_detail.length;
				}
				if (!data) {
					data = {
						belong_type: "3",
						container_type_name: "",
						ctyard_id: 0,
						ctyard_name: '',
						factory_id: 0,
						factory_name: '',
						mark: "",
					};
				}
				this.business_container_detail.splice(index, 0, {
					status:'1',
					belong_type: data.belong_type,
					business_container_id:'',
					container_type_name: data.container_type_name,
					ctyard_id: data.ctyard_id,
					ctyard_name: data.ctyard_name,
					factory_id: data.factory_id,
					factory_name: data.factory_name,
					mark: data.mark,
				});
			},
			business_container_detail_del(detail_index) {
				let business_container_item=this.business_container_detail[detail_index]
				if(business_container_item.status != 1){
					this.$my.other.msg({
						type:'warning',
						str:"此项不能删除"
					});
					return;
				}
				if(business_container_item.business_container_id){//去数据库删除

					//询问
					this.$my.other.confirm({
						content:"点击'确定'删除集装箱业务",
						confirm:()=>{

							//调用接口
							this.$my.net.req({
								data:{
									mod:"business",
									ctr:"business_container_del_by_truck_carrier",
									business_container_id:business_container_item.business_container_id,
								},
								callback:(data)=>{
									this.$my.other.msg({
										type:'success',
										str:"集装箱业务删除成功"
									});
									this.business_container_detail.splice(detail_index, 1);
								},
							});
						}
					});

				}else this.business_container_detail.splice(detail_index, 1);
			},

			//初始化
			init() {

				this.booking_num_has_ser=false;

				//清空表单数据
				this.form.booking_num = "";
				this.form.forwarder_id = 0;
				this.form.forwarder_name = "";
				this.form.port_id = 0;
				this.form.port_name = "";

				//清空堆场可预约时段
				this.business_container_detail = [];
			},
  		},
	};
</script>
<style lang="scss" scoped>
	.el_form_item {
		margin: 0;
	}
	.big_tit {
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
		position: relative;
	}
	.tab1_inner {
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width {
		width: 94%;
	}
	.bottomBtns {
		text-align: center;
		position: absolute;
		bottom: 0px;
		width: calc(100% - 60px);
		padding: 15px 0;
		background-color: #fff;
		border-top: 1px solid #ccc;
	}

	.form {
		overflow-y: auto;
		margin-top: -20px;
	}

	.tab_height {
		height: 450px;
	}

	.page {
		position: relative;
		background-color: #fff;
		border-radius: 10px;
		height: 100%;
		width: 1040px;
		overflow: auto;
		margin: auto;
	}
</style>