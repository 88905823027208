<template>
	<el-dialog 
		:title="$dict('工厂选择')" 
		top="1vh" width="90%" 
		append-to-body 
		custom-class="el-dialog-s" 
		:visible.sync="is_show_in_page" 
		@close="page_close"
	>
		<div :style="{height:dialog_inner_height}">
			
			<!-- 搜索条件表单 -->
			<el-form class="ser_form" size="small"  label-width="140px">
				<el-form-item class="el_form_item" :label="$dict('工厂名称')">
					<el-input class="el_input" v-model="form.name" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" :label="$dict('备注')">
					<el-input class="el_input" v-model="form.mark" clearable></el-input>
				</el-form-item>
				<el-form-item label-width="0">
					<el-button type="primary" @click="page_ser">{{ $dict('查询') }}</el-button>
				</el-form-item>
			</el-form>

			<!-- 表格 -->
			<div class="tab_height">
				<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
					<el-table-column :label="$dict('添加时间')" width="145">
						<template slot-scope="scope">
							<div>{{scope.row.create_time_text}}</div>
						</template>
					</el-table-column>
					<el-table-column :label="$dict('名称')">
						<template slot-scope="scope">
							<div>{{scope.row.name}}</div>
						</template>
					</el-table-column>
					
					<!-- 操作行 -->
					<el-table-column :label="$dict('操作')" width="120">
						<template slot-scope="scope">
							<el-button @click="choose(scope.row)" size="mini" type="text">{{ $dict('选定') }}</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>

			<!-- 分页 -->
			<el-pagination 
				class="margin-top-xl" 
				@size-change="page_size_change" 
				@current-change="page_current_change" 
				:current-page="page.p" 
				:page-sizes="[10, 20, 30, 40]" 
				:page-size="page.num" 
				:total="list.total*1" 
				layout="total, sizes,prev, pager, next, jumper" 
			></el-pagination>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		props:{
			is_show:Number,
		},
		data() {
			return {

				is_show_in_page:false,
				dialog_inner_height:'',

				//搜索条件
				form: {
					name:'',
					status:'2',
					mark:'',
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//监听页面高度
					this.dialog_inner_height=parseInt(document.body.clientHeight)-160+'px'
					window.onresize=()=>{
						this.dialog_inner_height=parseInt(document.body.clientHeight)-160+'px'
					}

					//打开弹出层
					this.is_show_in_page=true;

					//读取用户组数据
					this.page_ser()
					
				}else this.is_show_in_page=false;
			}
		},
		methods: {

			//选定
			choose(item){

				//通知
				this.$emit('choosed',item);
			},

			//页面关闭时
			page_close(){

				//结束监听
				window.onresize=null;
			},

			//搜索
			page_ser(){
				this.page.p=1
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){
				this.page.num=num
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				this.page.p=p
				this.get_page_data()
			},

			//读取用户组数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'factory',
						ctr:'factory_list_by_truck_carrier',
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//创建时间
							item.create_time_text=this.$my.other.totime(item.create_time);
						}

						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 80px);
	}
	.el_form_item{
		width: 25%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
	.el-dialog-s{
		z-index: 11;
		height:calc(100% - 2vh) !important;
	}
</style>